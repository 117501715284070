<template>
	<div id="summary" class="mt-5">
		<v-container v-if="!loading">
			<v-row>
				<div class="col-md-12 col-sm-12">
					<v-alert text color="#4CAF50" border="left" v-if="rejectSuccess" class="pa-2">
						<v-icon color="#4CAF50">mdi-close-circle-outline</v-icon>
						{{ $t("signature_request_rejected") }}
					</v-alert>
					<v-alert text color="#FF000D" border="left" v-if="error" class="pa-2">
						<v-icon color="#FF000D">mdi-close-circle-outline</v-icon>
						{{ errorMsg }}
					</v-alert>
				</div>
				<div id="pdf-view" class="col-md-6 col-sm-12" v-if="!rejectSuccess">
					<page-title class="text-center text-md-left" :title="title"></page-title>
				</div>
				<div id="pdf-view" class="col-md-6 col-sm-12" v-if="document && !rejectSuccess">
					<div class="text-center text-md-right mb-3">
						<span class="user-name-orange">{{ document.signerName }}</span><br />
						<span v-if="document.signerId" class="user-ref-number">{{ document.signerId.id }}</span>
					</div>
				</div>
			</v-row>

			<div id="customer-alert" v-if="rejectSuccess">
				<v-card outlined class="pa-1">
					<v-row>
						<div class="col-md-12 col-xs-12">
							<v-list two-line>
								<v-list-item background-color="white">
									<v-list-item-content>
										<v-list-item-title class="mb-1">
											<h3>{{ title }}</h3>
										</v-list-item-title>
										<v-list-item-subtitle class="color_gray--text font-weight-medium">
											{{ signatureTransactionData.ownerName }}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
								<v-list-item>
									<h4>{{ $t("signatories") }}</h4>
									<v-avatar color="#F36E21" class="ml-6 pa-3" size="16">
										<span class="white--text">{{ signatureCount }}</span>
									</v-avatar>
								</v-list-item>
							</v-list>
						</div>
					</v-row>
					<v-row>
						<div class="col-md-4 col-sm-12" v-for="(item, index) in signatureRequestList" :key="index">
							<v-list>
								<v-list-item class="pa-0">
									<v-list-item-content class="pa-0">
										<v-list-item-subtitle class="color_gray--text font-weight-medium">
											{{ item.name }}
										</v-list-item-subtitle>
										<p>{{ moment(item.date).format("DD/MM/YYYY H:m") }}</p>
										<p>{{ item.comments }}</p>
									</v-list-item-content>
									<v-list-item-action>
										<v-icon v-if="item.status === 'SIGNED'" :color="getIconColorByStatus('SIGNED')">
											mdi-checkbox-marked-circle-outline</v-icon>
										<v-icon v-if="item.status === 'REJECTED'" :color="getIconColorByStatus('REJECTED')">
											mdi-close-outline</v-icon>
										<v-icon v-if="item.status == 'NOTIFIED'" :color="getIconColorByStatus('NOTIFIED')">
											mdi-clock</v-icon>
									</v-list-item-action>
								</v-list-item>
							</v-list>
						</div>
					</v-row>
				</v-card>
			</div>

			<v-row v-if="rejectSuccess">
				<div class="col-md-12 col-xs-12 mt-1">
					<v-alert color="#F36E21" dark icon="mdi-fountain-pen-tip" prominent>
						{{ $t("summary_msg") }}.
						<p class="mb-0">
							{{ $t("summary_for_more") }}
							<a href="https://www.portasigma.com/" target="_blank" class="grey--text text--darken-4"
								rel="noopener">
								https://www.portasigma.com
							</a>
						</p>
					</v-alert>
				</div>
			</v-row>

			<v-row v-if="!rejectSuccess">
				<div class="col-md-12 col-xs-12 mt-10" v-if="hasComments">
					<v-textarea v-model="comment" rows="8" solo name="input-7-4"
						:label="$t('your_reasons_opl')"></v-textarea>
				</div>
				<div class="offset-md-5 col-md-2 col-xs-12 mt-1">
					<v-btn large color="#F36E21" block dark @click="reject()"> {{ $t("send") }} </v-btn>
				</div>
			</v-row>
		</v-container>

		<v-container style="height: 400px" v-if="loading">
			<v-row class="fill-height" align-content="center" justify="center">
				<v-col class="subtitle-1 text-center" cols="12">
					{{ $t("please_wait") }} ....
				</v-col>
				<v-col cols="6">
					<v-progress-linear color="deep-purple accent-4" indeterminate rounded height="6"></v-progress-linear>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import moment from "moment";
import PageTitle from "../components/shared/PageTitle.component";
import { SignaturesService } from "../services/signatures-service";

export default {
	name: "important-information",

	components: {
		PageTitle,
	},

	data: function () {
		return {
			title: "",
			comment: "",
			loading: false,
			userToken: localStorage.getItem("auth_user_token"),
			requestId: localStorage.getItem("request_id"),
			authId: localStorage.getItem("auth_id"),
			transactionId: localStorage.getItem("transaction_id"),
			rejectSuccess: false,
			errorMsg: "",
			error: false,
			document: [],
			signatureTransactionData: [],
			signatureRequestList: [],
			signatureCount: 0,
			loadingDownload: false,
			hasComments: false,
		};
	},

	created: function () {
		this.moment = moment;
	},

	mounted() {
		this.loadRequestData();
	},

	methods: {
		loadRequestData() {
			this.loading = true;
			SignaturesService.getSignatureRequestData(this.requestId, this.authId)
				.then((response) => {
					this.signatureTransactionData = response.data.signatureTransaction;
					this.document = response.data;
					this.title = this.signatureTransactionData.document.title;
					this.hasComments = this.document.hasComments;
					this.loading = false;
				})
				.catch((error) => {
					console.log(error);
					this.loading = false;
				});
		},
		reject() {
			this.error = false;
			if (this.requestId) {
				this.loading = true;
				this.rejectSuccess = false;
				let data = { comments: this.comment, authId: this.authId };

				// let token = "";
				// if(localStorage.getItem("auth_user_token")){
				// 	token = localStorage.getItem("auth_user_token");
				// }

				SignaturesService.rejectCertificateDocument(this.userToken, data, this.requestId)
					.then((response) => {
						this.loading = false;
						if (response.data.status === "REJECTED") {
							this.rejectSuccess = true;
							this.comment = "";
							this.signatureTransactionData =
								response.data.signatureTransaction;
							this.createRequestList(response.data);
						}
					})
					.catch((error) => {
						this.loading = false;
						this.rejectSuccess = false;
						if (error.response.status == 403) {
							this.error = true;
							this.errorMsg = error.response.data.description;
						}
					});
			} else {
				this.error = true;
			}
		},
		createRequestList(data) {
			var vm = this;
			vm.signatureRequestList.push({
				name: data.signerName,
				date: data.dateAction,
				status: data.status,
				comments: data.comments,
			});

			if (data.signatureTransaction.signatureRequests.length > 1) {
				data.signatureTransaction.signatureRequests.map(function (item) {
					if (item.signerName && item.signerName !== "") {
						vm.signatureRequestList.push({
							name: item.signerName,
							date: item.dateAction,
							status: item.status,
							comments: item.comments,
						});
					}
				});
			}
			this.signatureCount = this.signatureRequestList.length;
		},
		getIconColorByStatus(type) {
			let color = "";
			switch (type) {
				case "SIGNED":
					color = "#4CAF50";
					break;

				case "REJECTED":
					color = "#970606";
					break;

				case "NOTIFIED":
					color = "#E0E0E0";
					break;
			}
			return color;
		},
		download() {
			if (this.transactionId) {
				this.loadingDownload = true;
				SignaturesService.getSignatureRequestDocument(
					this.requestId,
					this.authId
				)
					.then((response) => {
						var file = new Blob([response.data], { type: "application/pdf" });
						var fileURL = URL.createObjectURL(file);
						var a = document.createElement("a");
						a.href = fileURL;
						a.download = localStorage.getItem("file_name");
						a.click();
						window.URL.revokeObjectURL(fileURL);
						this.loadingDownload = false;
					})
					.catch((error) => {
						console.log(error);
						this.loadingDownload = false;
					});
			}
		},
	},
};
</script>
